
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default Vue.extend({
  name: 'Menu',
  data: () => ({
    tab: 'users'
  }),
  methods: {
    onChange() {
      this.$emit('change', this.tab)
    }
  }
})
