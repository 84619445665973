
import Vue from 'vue'
import Menu from '@/components/admin/Menu.vue'

export default Vue.extend({
  components: {
    Menu
  },
  data:() => ({
    tab: 'users'
  }),
  computed: {
    tabTitle() {
      return this.$vuetify.lang.t("$vuetify.admin." + this.tab +".title")
    }
  },
  methods: {
    onChange(tab: string) {
      let splitTab = tab.split("/")
      this.tab = splitTab[splitTab.length -1]
    }
  }
})
